import { useTranslation } from 'react-i18next';
import './header.scss'
import TranslationComponentConstants from '../../../constants/translation-language-constants';
import HeaderConstants from '../../../constants/header-constants';
import { STATUS_ID } from '../../../utils/constans';
import Adjuntar from '../../../images/adjuntar.svg';
import { PdsButton } from '@pragma-dev-kit/pragma-ds-react';

function Header(props) {
    const constants = HeaderConstants;
    const translationConstants = TranslationComponentConstants;
    const [translate] = useTranslation(translationConstants.USE_TRANSLATION_GLOBAL_VALUE);
    let title = constants.EMPTY;
    let description = constants.EMPTY;

    switch (constants.TRUE) {
        case props.policy && props.context.estadoId === constants.NUMBER_ONE:
            title = translate('policy.pendingPolicy.approvePolicy');
            description = translate('policy.pendingPolicy.approvePolicyDescription');
            break;
        case props.policy && props.context.estadoId === constants.NUMBER_FIVE:
            title = translate('policy.historicPolicy.policy');
            description = translate('policy.historicPolicy.policyDescription');
            break;
        case props.policy:
            title = translate('invoices.createInvoice.requestPolicy');
            description = translate('invoices.createInvoice.requestPolicyDescription'); 
            break;
        case (props.context.estadoId === constants.NUMBER_ONE && !props.context.facSolicitudId) || props.context.estadoId === constants.NUMBER_EIGHT:
            title = translate('invoices.createInvoice.title');
            description = translate('invoices.createInvoice.createInvoiceDescription');
            break;
        case !props.policy && (props.context.estadoId === constants.NUMBER_FIVE || props.context.estadoId === constants.NUMBER_SIX): 
            title = translate('invoices.historicInvoice.historicTitle');
            description = translate('invoices.historicInvoice.historicDescription');
            break;
        default:
            title = translate('invoices.pendingInvoice.invoiceApproved');
            description = translate('invoices.pendingInvoice.invoiceApprovedDescription');
            break;
    };
    
    const canShowDuplicateButton = !props.policy && 
        (props.context.estadoId === STATUS_ID.PENDIENTE 
        || props.context.estadoId === STATUS_ID.APROBADA || props.context.estadoId === STATUS_ID.CANCELADA ) 
        && props.context.facSolicitudId 

    return (
        <div className="Header">
            <div className="Primary">
                <div className="TitleContent">
                    <h1 className="title">{title}</h1>
                    <p className="description">{description}</p>
                </div>
            </div>
             {  
                canShowDuplicateButton && (
                <div className="HeaderInformation__button">
                    <PdsButton icon={Adjuntar} onClick={props.handleDuplicate}>{translate('invoices.historicInvoice.duplicateInvoice')}</PdsButton>
                </div> 
            )}
        </div>
    );
}

export default Header;
