const ConceptosConstants = {

  GLOBAL: 'global',
  
  FALSE: false,

  CURRENCY: {
    USD: 'USD',
    COP: 'COP'
  },

  NUMBERS: {
    MINUS_ONE: -1,
    ZERO: 0,
    ONE: 1,
    TWO: 2
  },

  STATUS: {
    SUCCESS: 200,
  },

  ON_CHANGE_CONCEPT: 'onChangeConcept',
  COST_CENTER_TOTAL: 'costCenterTotal',

  PIRANI_COLOMBIA_KEY: 'Pirani - Colombia',

  INITIAL_DATE_KEY: "fecha_inicio",
  FINAL_DATE_KEY: "fecha_fin",

  FORMAT_DATE_YYYY_MM_DD: "YYYY/MM/DD",



}

export default ConceptosConstants