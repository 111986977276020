let date = new Date();
const a = JSON.stringify(date);

let year = a.substring(1, 5);
let month = a.substring(6, 8);
let day = a.substring(9, 11);
let completDateParameterMin = year + '-' + month + '-' + day;

export const initialState = {
    clienteId: -1, // Ya esta
    conceptos: [],
    estadoId: 1, //Ya esta. Va el dato quemado.
    fechaLimiteEntrega: completDateParameterMin, // Ya esta
    fechaSolicitud: completDateParameterMin, // Ya esta
    monedaId: 1, // Ya esta
    observaciones: "", // Ya esta
    plazoPago: -1, // Ya esta
    usuarioId: "",
    contacto: "",
    cargoContacto: "",
    files: [], // Archivos por subir
    fecha_factura: "0001-01-01",  // PENDIENTE DE APROBACION
    filialId: 0, // Pirani o Pragma
    fecha_inicio: "", // Campo necesario siempre y cuando la filial sea Pirani Colombia
    fecha_fin: "",// Campo necesario siempre y cuando la filial sea Pirani Colombia
};

const invoiceReducer = (state, action) => {
    switch(action.type) {
        case 'RESET':
            initialState.files = [];
            initialState.monedaId = 1;
            initialState.clienteId = -1; 
            return initialState;
        case "AGREGAR":
            return {...state, ...action.payload };
        default:
            return {...state, ...action.payload };
    }
}

export default invoiceReducer;